<template>
  <div class="services_section publication_wrapper">
    <TopBanner :title="$t('portal.magazine')" />
    <div class="breadcrumb_wrapper">
      <b-container>
        <b-row>
          <b-col>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/portal/home">{{ $t('portal.home') }}</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">{{ $t('portal.magazine') }}</li>
              </ol>
            </nav>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="service_container_wrapper magazineDetails_wrapper">
      <b-container>
        <b-row>
          <b-col sm="3">
            <Sidebar :isActive="isActive" />
            <div class="subscription_wrapper card">
              <a href="javascript:">
                <div class="card-header">
                  <h4 class="title text-white tada"><i class="far fa-hand-point-up"></i> সাবস্ক্রিপশন</h4>
                </div>
                <div class="card-body">
                  <p class="mb-0">ম্যাগাজিনের হার্ডকপির জন্য সাবস্ক্রিপশন করুন</p>
                </div>
              </a>
            </div>
            <div class="sidebar_wrapper">
              <div class="card">
                <div class="card-header">
                  <h5 class="title"><i class="fas fa-align-center"></i> আর্টিকেল সমূহ</h5>
                </div>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item"><a href="javascript:">কৃষিতে উদ্ভাবন</a></li>
                  <li class="list-group-item"><a href="javascript:">কৃষি প্রযুক্তি</a></li>
                  <li class="list-group-item"><a href="javascript:">কৃষিতে নারী</a></li>
                  <li class="list-group-item"><a href="javascript:">চাষ পদ্ধতি</a></li>
                  <li class="list-group-item"><a href="javascript:">কৃষি উদ্যোক্তা</a></li>
                </ul>
              </div>
            </div>
          </b-col>
          <b-col sm="9">
            <b-overlay :show="unitLoad">
              <div class="pub_filter_wrapper">
                <div class="row g-3">
                  <div class="col-sm-3 mb-3">
                    <label for="title" class="form-label">টাইটেল</label>
                    <select class="form-control">
                      <option selected>Select Title</option>
                      <option value="1">Krishi Kotha -1</option>
                      <option value="2">Krishi Kotha -2</option>
                      <option value="3">Krishi Kotha -3</option>
                    </select>
                  </div>
                  <div class="col-sm-3 mb-3">
                    <label for="month" class="form-label">মাস</label>
                    <select class="form-control">
                      <option selected>Select Month</option>
                      <option value="1">Vadro</option>
                      <option value="2">Assin</option>
                      <option value="3">Kartik</option>
                    </select>
                  </div>
                  <div class="col-sm-3 mb-3">
                    <label for="year" class="form-label">বছর</label>
                    <select class="form-control">
                      <option selected>Select Year</option>
                      <option value="1">2019</option>
                      <option value="2">2020</option>
                      <option value="3">2021</option>
                    </select>
                  </div>
                  <div class="col mb-3">
                    <div class="btn_filter_wrapper">
                      <button type="button" @click="searchData" class="btn btn-primary w-100">অনুসন্ধান করুন</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="service_list_wrapper">
                <div class="card card_wrapper">
                  <div class="card-header">
                    <h5 class="title"><i class="fas fa-history"></i> সাম্প্রতিক পত্রিকা</h5>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div v-for="(item, index) in publication.slice(0, 3)" :key="index" class="col-sm-3">
                        <a href="javascript:" title="Krishi Kotha">
                          <div class="card_item">
                            <div class="img_wrapper magazine_wrapper">
                              <div class="bookWrap">
                                <div class="book">
                                  <img class="cover" src="../../../../assets/images/portal/64277766.jpg">
                                </div>
                              </div>
                            </div>
                            <p class="title">{{ currentLocale == 'bn' ? item.pub_title_name_bn : item.pub_title_name }}</p>
                            <small class="date">{{ (currentLocale == 'bn' ? item.bangla_month_name_bn : item.bangla_month_name) + ' ' + $n(item.year_bn, { useGrouping: false }) }}</small>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card card_wrapper">
                  <div class="card-header">
                    <h5 class="title"><i class="fas fa-layer-group"></i> সমগ্র পত্রিকা</h5>
                    <div>
                      <a href="javascript:"><span class="badge badge-light">আরো দেখুন</span></a>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div v-for="(item, index) in publication" :key="index" class="col-sm-3">
                        <a href="javascript:" title="Krishi Kotha">
                          <div class="card_item">
                            <div class="img_wrapper magazine_wrapper">
                              <div class="bookWrap">
                                <div class="book">
                                  <img class="cover" src="../../../../assets/images/portal/64277766.jpg">
                                </div>
                              </div>
                            </div>
                            <p class="title">{{ currentLocale == 'bn' ? item.pub_title_name_bn : item.pub_title_name }}</p>
                            <small class="date">{{ (currentLocale == 'bn' ? item.bangla_month_name_bn : item.bangla_month_name) + ' ' + $n(item.year_bn, { useGrouping: false }) }}</small>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <b-pagination
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                />
              </div>
            </b-overlay>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
import TopBanner from '../../components/publication/TopBanner.vue'
import Sidebar from '../../components/publication/Sidebar.vue'
import RestApi, { agriResearchServiceBaseUrl } from '@/config/api_config'
import { magazineListApi } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
export default {
  mixins: [ModalBaseMasterList],
  components: {
    TopBanner,
    Sidebar
  },
  data () {
    return {
        isActive: 1,
        publication: [],
        unitLoad: false
    }
  },
  created () {
    this.loadData()
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    }
  },
  methods: {
    async searchData () {
      this.loadData()
    },
    getMonthBnName (monthId) {
        const objectData = this.$store.state.commonObj.banglaMonthList.find(type => type.value === monthId)
        if (this.$i18n.locale === 'bn') {
            return objectData.text_bn
        } else {
            return objectData.text_en
        }
    },
    loadData () {
      this.unitLoad = true
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      // this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(agriResearchServiceBaseUrl, magazineListApi, params).then(response => {
          if (response.success) {
            this.publication = this.getRelationalData(response.data.data)
            this.paginationData(response.data)
          }
          this.unitLoad = false
          // this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelationalData (data) {
      const listData = data.map(item => {
          const tmpType = this.$store.state.Portal.agriResearchObj.publicationTypeList.find(newItem => newItem.value === item.pub_type_id)
          const tmpTitle = this.$store.state.Portal.agriResearchObj.publicationTitleList.find(newItem => newItem.value === item.pub_title_id)
          const tmpBanglaMonth = this.$store.state.commonObj.banglaMonthList.find(newItem => newItem.value === parseInt(item.month_bn))
          const newData = {
              pub_type_name: tmpType !== undefined ? tmpType.text_en : '',
              pub_type_name_bn: tmpType !== undefined ? tmpType.text_bn : '',
              pub_title_name: tmpTitle !== undefined ? tmpTitle.text_en : '',
              pub_title_name_bn: tmpTitle !== undefined ? tmpTitle.text_bn : '',
              bangla_month_name: tmpBanglaMonth !== undefined ? tmpBanglaMonth.text_en : '',
              bangla_month_name_bn: tmpBanglaMonth !== undefined ? tmpBanglaMonth.text_bn : ''
          }
          return Object.assign({}, item, newData)
      })
      return listData
    }
  }
}
</script>
